<template>
  <div class="signUp">
    <el-tabs @tab-click="handleClick" v-model="campStatus">
      <el-tab-pane label="正在报名" name="1">
        <template v-if="campStatus==1">
          <campList :typeFu="campStatus"></campList>
        </template>

      </el-tab-pane>
      <el-tab-pane label="即将开营" name="2">
        <template v-if="campStatus==2">
          <campList :typeFu="campStatus"></campList>
        </template>
      </el-tab-pane>
      <el-tab-pane label="开营中" name="3">
        <template v-if="campStatus==3">
          <campList :typeFu="campStatus"></campList>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已结营" name="4">
        <template v-if="campStatus==4">
          <campList :typeFu="campStatus"></campList>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已退营" name="5">
        <template v-if="campStatus==5">
          <campList :typeFu="campStatus"></campList>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import campList from "@/components/base/myInfo/campList.vue";
  import {activity} from "r/base/myInfo";

  export default {
    name: "signUpList",
    components: {
      campList
    },
    data() {
      return {
        campStatus: '1',
      }
    },
    created() {
      this.commonApi(39)

    },
    methods: {
      handleClick(tab, event) {
        this.campStatus = tab.name;
        this.commonApi(39)
      },

    }

  }
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
  }

  .signUp {
    margin: 20px 30px;

    ::v-deep .el-tabs {
      .el-tabs__active-bar {
        background-color: var(--color);

      }

      .el-tabs__item {
        font-size: 16px;

        &:hover,
        &.is-active {
          color: var(--color);
        }
      }

      .el-tabs__content {
        padding: 20px;
      }
    }
  }
</style>
