/* eslint-disable vue/require-v-for-key */
<template>
  <!--底部-->
  <div class="footer">
    <div class="content">
      <div class="com-container">
        <div class="contact_us">
          <div class="logo-img">
            <img src="../assets/img/logo_f.png"/>
          </div>
          <div class="contact_list">
            <!--<div class="contact_list_title">联系我们</div>-->
            <div class="contact_list_item">
              <!--<i class="el-icon-message"></i>-->
              <span>联系邮箱：</span>service@campcenter.cn
            </div>
            <!--<div class="contact_list_item">
              <i class="el-icon-aim"></i>
              <span>工作时间:</span>周一至周五 09:00 - 12:00，14:00 - 18:00
            </div>-->
          </div>
        </div>
        <div class="contact_qr">
          <div class="contact_qr_item">
            <img src="../assets/img/footer/qr_1.jpg"/>
            <div class="name">CAMP CENTER公众号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyRight">
      <div class="com-container">
        <a href="">Copyright © 2023 营地系统 版权所有</a>
        <a href="https://beian.miit.gov.cn" target="_blank">京ICP备20001854号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  export default {
    name: "Footer",
  };
</script>

<style lang="scss" scoped>
  @import "a/scss/common";

  .footer {
    font-size: 14px;
    opacity: 0.9;
    font-family: PingFang SC;

    .content {
      padding: 30px 0 20px;
      background: linear-gradient(to right, #44A7ED 0%, #1CCAED 80%);

      .com-container {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        .contact_us {
          flex: 1;
          display: flex;
          flex-flow: row;
          /*justify-content: space-between;*/

          .logo-img {
            height: 30px;

            > img {
              height: 30px;
            }
          }

          .contact_list {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0;
            /*margin-left: 35%;*/
            margin-left: 20%;

            .contact_list_title {
              font-size: 18px;
              line-height: 40px;
              color: #ffffff;
              margin-bottom: 10px;
            }

            .contact_list_item {
              font-size: 14px;
              height: 24px;
              line-height: 24px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }

        .contact_qr {
          /*flex: 1;*/
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: flex-end;

          .contact_qr_item {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            margin-left: 10px;

            img {
              width: 88px;
            }

            .name {
              font-size: 14px;
              line-height: 24px;
              margin: 10px 0;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;

            }
          }
        }
      }
    }

    .copyRight {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin: 0;
      padding: 0;
      background: #005897;
      line-height: 30px;

      a {
        font-size: 12px;
        font-weight: 400;
        margin-right: 20px;
        color: #cccccc;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
