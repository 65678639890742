import {request} from '../network'

//我的营地列表
export function activity(data) {
  return request({
    method: 'get',
    url: '/web/download/stu/activity',
    params: data
  })
}
