<template>
  <el-container>
    <el-header height="104px">
      <Header :typeFu="4"></Header>
      <div class="bg-top"></div>
    </el-header>
    <el-container>
      <div class="com-container index-container">
        <div class="camp_left" ref="leftBoxFu">
          <ul
            :class="{
              'is-fixed': isPosition == 1,
              'is-absolute': isPosition == 2,
            }"
            class="camp_left_box"
            ref="leftBox"
          >
            <li
              :class="{ active: campindex_type === index }"
              :key="index"
              @click="ToSignUp(index)"
              v-for="(item, index) in tabs"
            >
              <img :src="item.url"/>
              {{ item.desc }}
            </li>
          </ul>
        </div>
        <div class="camp_right" ref="rightBox">
          <signUpList v-if="this.campindex_type==0"></signUpList>
        </div>
      </div>
      <Footer></Footer>
    </el-container>
  </el-container>
</template>

<script>
  /* eslint-disable */
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import svg1 from '@/assets/img/signUp/icon.svg';
  import signUpList from '../myInfo/signUp.vue';

  export default {
    name: "myInfoIndex",
    components: {
      Header,
      Footer,
      signUpList
    },
    // props:{}
    data() {
      return {
        index: 0,
        isPosition: 0, // 左侧box是否浮动
        pageYOffset: 0, // 左侧box浮动时，滚动的高度
        scorllHeight: 0, // 最大滚动高度
        tabs: [
          {
            id: "0",
            desc: "我的报名",
            icon: "icon-icon1",
            url: svg1
          },

        ],
        campindex_type: 0,
      };
    },
    watch: {},
    created() {
      // this.ToSignUp(this.campindex_type)
    },
    mounted() {
      window.addEventListener("scroll", this.handleScrollX, true);
      window.addEventListener("resize", this.onResize, true);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScrollX, true);
      window.removeEventListener("resize", this.onResize, true);
    },
    methods: {
      ToSignUp(index) {
        this.campindex_type = index;
        if (this.campindex_type == 0) {
          this.$router.push({
            path: "/myInfo/signUpList",
            query: {
              ...this.$store.state.query,
            }
          });
        }
      },
      onResize() {
        const refLeft = this.$refs["leftBox"];
        const leftBoxFu = this.$refs["leftBoxFu"];
        refLeft.style.width = leftBoxFu.offsetWidth + "px";
      },
      // 页面滚动事件
      handleScrollX() {
        const top = document.documentElement.scrollTop || document.body.scrollTop;
        const refLeft = this.$refs["leftBox"];
        const leftBoxFu = this.$refs["leftBoxFu"];
        const refRight = this.$refs["rightBox"];
        if (top > 0) {
          if (this.isPosition == 0) {
            this.scorllHeight = refRight.offsetHeight - refLeft.offsetHeight;
            refLeft.style.width = leftBoxFu.offsetWidth + "px";
          }
          this.isPosition = 1;
        } else {
          if ((this.isPosition = 1)) {
            this.isPosition = 0;
          }
        }
        if (top >= this.scorllHeight) {
          if (this.isPosition == 1) {
            this.isPosition = 2;
          }
        } else if (top < this.scorllHeight) {
          if (this.isPosition == 2) {
            this.isPosition = 1;
            refLeft.style.width = leftBoxFu.offsetWidth + "px";
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "a/scss/common";
  @import "a/scss/index";
  @import "a/style";

  @font-face {
    font-family: "icomoon";
    src: url("../../../assets/fonts/icomoon.eot?dcunb6");
    src: url("../../../assets/fonts/icomoon.eot?dcunb6#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/icomoon.ttf?dcunb6") format("truetype"),
    url("../../../assets/fonts/icomoon.woff?dcunb6") format("woff"),
    url("../../../assets/fonts/icomoon.svg?dcunb6#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  .camp_left i {
    font-family: "icomoon";
  }

  .el-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    min-width: 1150px;
    background-color: #f8f8f8;

    .el-header {
      position: fixed;
      width: 100%;
      padding: 0;
      background-color: #ffffff;
      z-index: 2000; //消息提示框z-index=2019

      .bg-top {
        height: 24px;
        background-color: #f8f8f8;
      }
    }

    .el-container {
      width: 100%;
      display: flex;
      flex-flow: column;
      margin-top: 104px;

      .index-container {
        padding: 0 0 50px 0;
        display: flex;
        flex-flow: row;
        flex: 1;

        .camp_left {
          position: relative;
          width: 22%;
          max-width: 280px;
          min-width: 200px;
          height: 300px;

          background-color: #ffffff;
          border-radius: 8px;
          padding: 20px 0;
          margin-right: 25px;

          .camp_left_box {
            padding-inline-start: 0 !important;
          }

          &.is-fixed {
            position: fixed;
            top: 104px;
            max-width: 280px;
            min-width: 216px;
          }

          &.is-absolute {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
        .camp_right {
          flex: 1;
          border-radius: 8px;
          background-color: #ffffff;
        }

        li {
          position: relative;
          padding: 0 23px 0 51px;
          height: 44px;
          line-height: 44px;
          margin: 10px 0;
          font-size: 15px;
          list-style: none;
          border-left: 4px solid transparent;

          img {
            vertical-align: middle;
            margin: -3px 8px 0 0;
            width: 20px;
            position: absolute;
            left: -1000px;
            filter: drop-shadow(var(--color) 1023px 13px) !important;
          }

          &.active,
          &:hover {
            border-left: 4px solid var(--color);
            cursor: pointer;
            background-color: #f8f8f8;
          }
        }



      }

    }
  }
</style>
