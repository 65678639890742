<template>
  <div>
    <template v-if="activeList.length>0">
      <div class="campList" v-for="(item,index) in activeList" :key="index">
        <div @click="toSignUp(item.identity)">
          <el-card>
            <div class="flex list_item">
              <div class="item_img">
                <el-image v-if="!item.activityCover" :src="item.systemLogo" fit="cover"/>
                <el-image v-else :src="item.activityCover" fit="cover"/>
              </div>
              <div class="right">
                <div class="item_title">{{item.name}}</div>
                <div class="sign-box" v-if="item.activityLabel">
                  <div class="sign"
                       v-for="(item2, index2) in item.activityLabel.split(',')"
                       :key="index2">{{item2}}
                  </div>
                </div>
                <div class="item_time">活动时间：{{formatYMD(item.activityStartTime)}} 至
                  {{formatYMD(item.activityEndTime)}}
                </div>
              </div>
            </div>
          </el-card>
        </div>

      </div>
    </template>
    <div class="noCamp" v-else>暂无营地</div>
  </div>
</template>

<script>
  import {activity} from "r/base/myInfo";

  export default {
    name: "campList",
    props: {
      typeFu: [String, Number]
    },
    data() {
      return {
        activeList: [],
        type: this.typeFu,
      }
    },

    created() {
      this.getCampList();
    },
    methods: {
      getCampList() {
        activity({
          phone: window.localStorage.getItem('index-phone-all'),
          type: this.type
        }).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.activeList = res.data.data;
        })
      },
      toSignUp(code) {
        if(this.type==5){
          return false;
        }
        this.$store.commit('setIndexIdentity', code)
        this.$router.push({
          path: "/signUp/undefined",
          query: {
            ...this.$store.state.query,
            code: code
          }
        });
      }
    },
    watch: {
      typeFu() {
        this.type = this.typeFu;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
  }

  .campList {
    margin: 20px 0;

    .list_item {
      display: flex;
      flex-flow: row;

      .item_img {
        width: 30%;
        height: 9.8vw;
        min-height: 114px;
        max-height: 198px;
        margin-right: 10%;
        overflow: hidden;
      }

      .right {
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: space-around;

        .item_title {
          font-size: 15px;
        }

        .sign-box {
          font-size: 10px;
          display: inline-block;
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }

          .sign {
            display: inline-block;
            padding: 0 14px;
            border-radius: 13px;
            background-color: #eeeeee;
            margin: 5px 10px 5px 0;
            line-height: 26px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .item_time {
          font-size: 13px;
          color: gray;
        }
      }
    }
    ::v-deep .el-card {
      cursor: pointer;

      .el-image {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transition: all .5s;
        -webkit-transition: all .5s;

        .el-image__error {
          background-color: #D8D8D8;
        }
      }

      &:hover {
        box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1);

        .el-image {
          transform: scale(1.1);
          -ms-transform: scale(1.1); /* IE 9 */
          -moz-transform: scale(1.1); /* Firefox */
          -webkit-transform: scale(1.1); /* Safari 和 Chrome */
          -o-transform: scale(1.1);
        }
      }
    }
  }

  .noCamp {
    text-align: center;
    margin-top: 100px;
  }
</style>
